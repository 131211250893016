import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800" }
const _hoisted_2 = { class: "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800" }
const _hoisted_3 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-600" }
const _hoisted_4 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-600" }
const _hoisted_5 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-600" }
const _hoisted_6 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-600" }
const _hoisted_7 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-600" }
const _hoisted_8 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-600" }
const _hoisted_9 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-600" }
const _hoisted_10 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-600" }
const _hoisted_11 = { class: "px-6 py-4 whitespace-nowrap text-right text-sm font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopyButton = _resolveComponent("CopyButton")!
  const _component_Switch = _resolveComponent("Switch")!
  const _component_BaseTableEditDeleteButtons = _resolveComponent("BaseTableEditDeleteButtons")!
  const _component_AreYouSureModal = _resolveComponent("AreYouSureModal")!
  const _component_EditClinicWorkerModal = _resolveComponent("EditClinicWorkerModal")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", _hoisted_1, _toDisplayString(_ctx.worker.name), 1),
    _createElementVNode("td", _hoisted_2, [
      _createVNode(_component_CopyButton, {
        content: _ctx.worker.uid
      }, null, 8, ["content"])
    ]),
    _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.GENDERS[_ctx.worker.gender]), 1),
    _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.worker.personalNo), 1),
    _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.worker.radiationWorkStartYear), 1),
    _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.worker.radiationWorkerCategory), 1),
    _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx.worker.registrationDetails), 1),
    _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.worker.position), 1),
    _createElementVNode("td", _hoisted_9, _toDisplayString(_ctx.trainingTime), 1),
    _createElementVNode("td", _hoisted_10, [
      _createVNode(_component_Switch, {
        value: _ctx.worker.active,
        onClick: _ctx.toggleWorkerStatus
      }, null, 8, ["value", "onClick"])
    ]),
    _createElementVNode("td", _hoisted_11, [
      _createVNode(_component_BaseTableEditDeleteButtons, {
        onOnEdit: _ctx.editWorker,
        onOnDelete: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteWorkerModal = true)),
        "show-delete": _ctx.isAdmin
      }, null, 8, ["onOnEdit", "show-delete"]),
      (_ctx.deleteWorkerModal)
        ? (_openBlock(), _createBlock(_component_AreYouSureModal, {
            key: 0,
            onOnAction: _ctx.onDeleteWorkerModalClose
          }, null, 8, ["onOnAction"]))
        : _createCommentVNode("", true),
      (_ctx.editWorkerModal)
        ? (_openBlock(), _createBlock(_component_EditClinicWorkerModal, {
            key: 1,
            onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editWorkerModal = false)),
            worker: _ctx.worker
          }, null, 8, ["worker"]))
        : _createCommentVNode("", true)
    ])
  ]))
}